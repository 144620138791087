<template>
    <div class="min-h-[500px]">
        <div class="py-2 h-[50px] w-full border-gray-100">
            <div class="flex px-2">
                <el-input
                    v-model.trim="searchName"
                    class="flex-1 max-w-full w-full"
                    placeholder="搜尋名稱"
                    :disabled="loading"
                    @keyup.enter.native="filterSearch"
                    @clear="filterSearch"></el-input>
            </div>
        </div>
        <div>
            <ul id="providers" ref="providers" class="overflow-y-auto max-h-[500px] bg-white">
                <li
                    v-for="(item, index) in realTimeProviderChatUsers"
                    v-show="item.banana_id !== adminChatId"
                    :id="item.banana_id"
                    :key="index"
                    class="cursor-pointer flex items-center px-5 py-2.5"
                    :class="item.banana_id === serviceChatId ? 'bg-yellow-100' : 'bg-white'"
                    @click="changeRoom(item.banana_id)">
                    <Avatar :isLogin="item.isOnline" :backgroundImg="item.avatar" />
                    <div class="ml-3 flex-1 flex items-center">
                        <span class="flex-1">
                            {{ item.name }}
                            <span v-if="$Debug">-{{ item.banana_id }}</span>
                            <span v-if="item.enableCityAi" class="bg-blue-800 p-1 text-white rounded-full"
                                ><font-awesome-icon class="text-lg" :icon="['fas', 'robot']"
                            /></span>
                        </span>
                        {{ item.unReadMessageCount }}
                        <span v-if="item.unReadMessageCount > 0" class="w-[10px] h-[10px] bg-red-500 rounded-full"></span>
                    </div>
                </li>
                <li v-if="loading" class="min-h-[50px] w-full text-center">
                    <img class="w-10 h-10 mx-auto block animate-spin" src="/img/loading.svg" alt="" />
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
// 導入大頭照組件
import Avatar from "@/components/Avatar";
// 導入 firebase 連接資料庫機制
import { firebaseConnectRef } from "@/plugins/firebase";
/**
 * firebaseGetUserData: 取得 firebase user資料
 */
/* eslint no-unused-vars: 0 */
import { firebaseGetUserData } from "@/service/firebaseChatSet";
export default {
    components: {
        Avatar,
        // InfiniteLoading
        // LoadingComponent
    },
    props: {
        // 當前服務商id
        serviceChatId: {
            type: String,
            default: null,
        },
        // 當前服務商聊天對象id
        currentReceiveUserId: {
            type: String,
            default: null,
        },
        // 當前聊天對象資料
        currentChatUserData: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    computed: {
        ...mapState("userStore", ["user"]),
        ...mapState("chatStore", ["providers", "lastKeyByProvider"]),
        ...mapGetters("chatStore", ["realTimeProviderChatUsers"]),
    },
    data() {
        return {
            datas: [],
            // 搜尋聊天對象
            searchName: "",
            // 預設頁面
            currentPage: 1,
            // 分頁資料
            paginationData: {
                limit: 100,
                total: 0,
                lastPage: 0,
            },
            // 判斷目前是往下滾還是網上滾
            isScrollDown: true,
            loading: false,

            filterData: {},
            // 判斷是否為系統客服
            adminChatId: process.env.VUE_APP_SERVICE_CHAT_ID,
        };
    },
    methods: {
        ...mapMutations("chatStore", ["setLastKey", "setChatUsersByIndex", "setResetChatUsers", "setProviderCityAiValue"]),
        ...mapActions("chatStore", ["getUsers", "getChatUsersPagination", "searchChatUsers"]),

        // 重置
        reset() {
            this.searchName = null;
        },
        // 搜尋條件
        async filterSearch() {
            this.setResetChatUsers();
            // 判斷是否有值 有值就帶入搜尋條件
            if (this.$isEmpty(this.searchName)) {
                this.getUserList();
                return;
            }
            this.searchChatUsers({
                isGetProviders: true,
                userId: this.serviceChatId,
                name: this.searchName,
                firebaseQuery: "providers",
            });
        },
        /**
         * 搜尋過濾條件 模糊比對方式
         * @param { type Array(陣列) } list 過濾名單
         * @param { type String(字串) } keyword 過濾關鍵字
         */
        fuzzyQuery(list, keyword) {
            var arr = [];
            list.forEach((item) => {
                if (item.name.indexOf(keyword) >= 0) {
                    arr.push(item);
                }
            });
            return arr;
        },
        /**
         * 更換聊天室
         * @param { type String(字串) } userId 會員id
         * @param { type Boolean(布林) } isChangeLastKey 判斷是否重新定義 firebase 分頁 lastKey (因為重新整理頁面時不可觸發 因此多傳個值判斷)
         */
        async changeRoom(userId, isChangeLastKey = true) {
            // 判斷為系統身份時不執行
            if (userId === this.adminChatId) {
                return;
            }
            // 判斷點擊同一位服務商時 不執行動作
            if (userId === this.serviceChatId) {
                return;
            }
            if (this.serviceChatId !== null) {
                // 判斷是否有點擊過服務商 有點擊過要切換服務商時須先取消監聽上一位服務商聊天對象
                this.disconnect();
            }
            // 設定聊天對象為 null
            this.$emit("update:currentReceiveUserId", null);
            // 取消聊天對象的未讀訊息以及更新時間監聽
            this.$emit("onDisconnectChatUserData");
            // 清空聊天對象
            this.setResetChatUsers();
            // 設定聊天對象id
            this.$emit("update:serviceChatId", userId);
            // 清空聊天室訊息
            this.$emit("onClearChatRoomMessages");
            // 清空搜尋名稱
            this.$emit("restSearchName", null);
            this.$nextTick(async () => {
                // 取消讀取歷史訊息事件
                this.$emit("update:isReadHistory", false);
                // 清空聊天對象資料
                this.$emit("update:currentChatUserData", {});
                // 清空輸入匡值
                this.$emit("onClearnMessage");
                // 更新當前聊天對象資料
                await this.setCurrentChatUserData(isChangeLastKey);
                // 判斷有搜尋聊天對象時 不往下執行
                if (!this.$isEmpty(this.searchName)) {
                    return;
                }
                this.$nextTick(async () => {
                    // 判斷當高度小於 500 時需動態更新視窗高度 因為預設高度最高限制 500 不然會沒有捲軸出現 無法觸發分頁機制
                    if (this.$refs.providers.scrollHeight < 500) {
                        await this.scrollDownGetPagination();
                    }
                });
            });
        },
        /**
         * 監聽聊天對象是否有資料更新
         */
        monitorUser(receiveUserId) {
            firebaseConnectRef("users").on("child_changed", (snapshot) => {
                // 判斷非登入使用者
                if (snapshot.val().enableCityAi !== undefined) {
                    // 取得更改值的服務商 索引位置
                    const index = this.providers.findIndex((user) => user.banana_id === snapshot.key);
                    // 判斷是否有取得
                    if (index !== -1) {
                        this.setProviderCityAiValue({ index, enableCityAi: snapshot.val().enableCityAi });
                    }
                }
            });
        },
        /**
         * 當前聊天對象資料
         * @param { type Boolean(布林) } isChangeLastKey 判斷是否重新定義 firebase 分頁 lastKey (因為重新整理頁面時不可觸發 因此多傳個值判斷)
         */
        async setCurrentChatUserData(isChangeLastKey = true) {
            try {
                const userData = await firebaseGetUserData(this.currentReceiveUserId, true, this.serviceChatId);
                // 當使用者資料沒有時 不往下執行
                if (userData === null) {
                    return;
                }
                if (isChangeLastKey) {
                    // 判斷updatedAt值 為 undefined 或其他因素時 給予預設值 現在時間
                    this.setLastKey({
                        isGetProviders: true,
                        lastKey: this.$isEmpty(userData.updatedAt) ? -this.$moment(userData.created_at).valueOf() : -userData.updatedAt,
                    });
                }
                // 用來更新切換機器人或真人客服時 找尋到對應的 index 去做更換聊天對象的顯示
                const index = this.realTimeChatUsers.findIndex((item) => {
                    return item.banana_id === userData.banana_id;
                });
                // 判斷有找到值時 才執行 去做更換聊天對象的顯示
                if (index !== -1) {
                    this.setChatUsersByIndex({ isGetProviders: true, index, data: userData });
                }
                if (!this.$isEmpty(userData)) {
                    this.$emit("update:currentChatUserData", userData);
                }
            } catch (err) {
                return {};
            }
        },
        // 取消 firebase 監聽
        disconnect() {
            if (this.currentReceiveUserId !== null && this.serviceChatId !== null) {
                firebaseConnectRef("chat_rooms").child(this.serviceChatId).child("users").child(this.currentReceiveUserId).off();
            }
            // 取消服務商聊天對象監聽
            firebaseConnectRef("chat_rooms").child(this.serviceChatId).child("users").off();
        },
        // 取得分頁資料
        getMoreData() {
            // 抓取元素
            const dom = this.$refs.providers;
            // 判斷當 滾動距離 加上 當前 div 高度 >= 滾動條高度時 觸發滾動更新分頁
            const getDataStart = dom.scrollTop + dom.clientHeight >= dom.scrollHeight;
            // 觸發滾動更新分頁
            if (getDataStart && this.$isEmpty(this.searchName)) {
                this.scrollDownGetPagination();
                return;
            }
            // //  當滾動高度 小於或等於 新人榜高度時做觸發
            if (dom.scrollTop <= 0) {
                // this.scrollUpGetPrevPagination();
            }
        },
        /**
         * 取得聊天對象列表
         * @param { type String(字串) } query 區分找尋會員或服務商聊天對象參數 (ex: "members" 會員, "providers" 服務商)
         * @example 服務商 [1,2] 會員 [0,0]
         */
        async getUserList(query = "providers") {
            await this.getUsers({
                isGetProviders: true,
                userId: this.serviceChatId,
                firebaseQuery: query,
                lastKey: this.lastKeyByProvider,
            });
            this.$nextTick(async () => {
                // 當捲軸高度小於500  且聊天對象大於0時 觸發
                if (this.$refs.providers.scrollHeight < 500 && this.providers.length > 0) {
                    await this.scrollDownGetPagination();
                }
            });
        },
        // 顯示載入動畫時間
        loadingTime() {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve(true);
                }, 500);
            });
        },

        /**
         * 分頁機制
         */
        async scrollDownGetPagination() {
            this.loading = true;
            // 判斷目前是往下滾狀態
            this.isScrollDown = true;
            if (this.lastKeyByProvider === "end") {
                console.log("is end");
                this.loading = false;
                return;
            }
            await this.loadingTime();
            await this.getChatUsersPagination({
                isGetProviders: true,
                userId: this.serviceChatId,
                currentReceiveUserId: this.currentReceiveUserId,
                firebaseQuery: "providers",
                lastKey: this.lastKeyByProvider,
                allUsers: this.providers,
            });
            this.loading = false;
            this.$nextTick(async () => {
                // 判斷當高度小於 500 時觸發
                if (this.$refs.providers.scrollHeight < 500) {
                    // 當目前卷軸高度小於聊天對象的視窗高度(500)時 觸發分頁機制 遞迴自己 (因為分頁中取得比數會因為過濾條件完後 變成筆數過少問題 因此遞迴自己)
                    await this.scrollDownGetPagination();
                } else {
                    // 如果超過 500 高度時 將高度設為 auto
                    // this.$refs.providers.style.height = "auto";
                }
                const dom = this.$refs.providers;
                dom.scrollTo({ top: dom.scrollTop });
            });
        },
        // 觸發視窗滾動事件
        windowSrcoll() {
            this.$nextTick(() => {
                this.$refs.providers.addEventListener("scroll", this.getMoreData);
            });
        },
        /**
         * 設定分頁資料
         * @param { type Array(陣列) } data 分頁資料
         */
        setDatas() {
            this.windowSrcoll();
        },
    },
    watch: {
        // // 判斷當清空輸入搜尋名稱時 觸發搜尋條件
        // searchName(val) {
        //     if (this.$isEmpty(val)) {
        //         this.filterSearch();
        //     }
        // },
        // async providers() {
        //     if (this.$refs.providers.scrollHeight < 500) {
        //         await this.scrollDownGetPagination();
        //     }
        // }
    },
    async created() {
        // 先清空預設定位分頁的 key
        this.setLastKey({ isGetProviders: true, lastKey: null });
        await this.getUserList();
        this.monitorUser();
        this.$nextTick(() => {
            this.setDatas();
        });
    },
    beforeDestroy() {
        if (this.serviceChatId !== null) {
            firebaseConnectRef("chat_rooms").child(this.serviceChatId).child("users").off();
        }
        firebaseConnectRef("users").off();
        this.$refs.providers.removeEventListener("scroll", this.getMoreData);
    },
};
</script>
