<template>
    <div class="grid grid-cols-12 gap-4 px-5">
        <div class="col-span-4 bg-white p-3 rounded-lg shadow-lg">
            <Providers
                :serviceChatId.sync="serviceChatId"
                :currentChatUserData.sync="currentChatUserData"
                :currentReceiveUserId.sync="currentReceiveUserId"
                @restSearchName="restSearchName"
                @onClearnMessage="clearnMessage"
                @onClearChatRoomMessages="clearChatRoomMessages"
                @onDisconnectChatUserData="disconnectChatUserData" />
        </div>
        <div class="col-span-4 bg-white p-3 rounded-lg shadow-lg">
            <ChatUsers
                ref="users"
                :serviceChatId.sync="serviceChatId"
                :isReadHistory.sync="isReadHistory"
                :currentChatUserData.sync="currentChatUserData"
                :currentReceiveUserId.sync="currentReceiveUserId"
                @onGetMessageList="getMessageList"
                @onClearnMessage="clearnMessage"
                @onClearChatRoomMessages="clearChatRoomMessages" />
        </div>
        <div class="col-span-4 bg-gray-50 rounded-lg shadow-lg">
            <Messages
                v-if="currentReceiveUserId !== null"
                ref="messages"
                :serviceChatId.sync="serviceChatId"
                :currentChatUserData.sync="currentChatUserData"
                :currentReceiveUserId.sync="currentReceiveUserId"
                :isReadHistory.sync="isReadHistory"
                @onResetCurrentUserData="resetCurrentUserData" />
            <SendMessage
                v-if="currentReceiveUserId !== null"
                ref="sendMessage"
                v-permission="['update']"
                :serviceChatId.sync="serviceChatId"
                :currentChatUserData.sync="currentChatUserData"
                :currentReceiveUserId.sync="currentReceiveUserId"
                :isReadHistory.sync="isReadHistory" />
        </div>
    </div>
</template>

<script>
import { mapMutations } from "vuex";
import Providers from "./components/Providers.vue";
import ChatUsers from "./components/ProviderChatUsers.vue";
import Messages from "../components/Messages.vue";
// 導入 發送聊天訊息組件
import SendMessage from "../components/SendMessage.vue";
// 導入 firebase 連接資料庫機制
import { firebaseConnectRef } from "@/plugins/firebase";

export default {
    components: {
        Providers,
        ChatUsers,
        Messages,
        SendMessage,
    },
    data() {
        return {
            // 服務商 id
            serviceChatId: null,
            // 當前聊天對象id
            currentReceiveUserId: null,
            // 當前聊天對象使用者資料
            currentChatUserData: {},
            // 判斷是否閱讀歷史訊息 (主要用意是判斷是否將聊天視窗滾到底)
            isReadHistory: false,
            // 發送訊息輸入匡值
            message: "",
        };
    },
    methods: {
        ...mapMutations("chatStore", ["setResetChatUsers"]),
        // 重新取得聊天室訊息
        getMessageList() {
            this.$nextTick(() => {
                if (this.$refs.messages !== undefined) {
                    this.$refs.messages.getMessageList();
                }
            });
        },
        // 清空輸入匡訊息
        clearnMessage() {
            this.$nextTick(() => {
                if (this.$refs.sendMessage !== undefined) {
                    this.$refs.sendMessage.clearnMessage();
                }
            });
        },
        // 清空聊天室訊息
        clearChatRoomMessages() {
            this.$nextTick(() => {
                if (this.$refs.messages !== undefined) {
                    this.$refs.messages.clearChatRoomMessages();
                }
            });
        },
        // 取消 firebase 監聽
        disconnect() {
            // 判斷是否有 user 資料 因為登出時會清出空
            if (this.serviceChatId !== undefined) {
                // 取消聊天對象未讀訊息數量監聽
                firebaseConnectRef("chat_rooms").off();
                // 取消聊天室監聽
                firebaseConnectRef("chats").off();
                // 判斷是否有顯示 聊天訊息組件
                if (this.$refs.messages !== undefined) {
                    // 取消監聽聊天對象
                    this.$refs.messages.disconnectChatUserData(this.serviceChatId, this.currentReceiveUserId);
                }
            }
        },
        /**
         * 返回聊天對象列表頁
         */
        resetCurrentUserData() {
            this.$nextTick(() => {
                if (this.$refs.users !== undefined) {
                    this.$refs.users.setCurrentChatUserData();
                }
            });
        },
        // 重置搜尋名稱
        restSearchName() {
            this.$nextTick(() => {
                if (this.$refs.users !== undefined) {
                    this.$refs.users.reset();
                }
            });
        },
        // 取消監聽聊天對象未讀訊息與更新時間監聽事件
        disconnectChatUserData() {
            // 判斷有聊天對象在觸發
            if (this.currentReceiveUserId !== null) {
                firebaseConnectRef("users").child(this.serviceChatId).child("users").child(this.currentReceiveUserId).off();
            }
        },
    },
    beforeDestroy() {
        this.serviceChatId = null;
        this.currentReceiveUserId = null;
        this.setResetChatUsers();
    },
};
</script>
