<template>
    <div>
        <div v-if="chatUsers.length > 0" class="py-2 h-[50px] w-full border-gray-100">
            <div class="flex px-2">
                <el-input
                    v-model.trim="searchName"
                    class="flex-1 max-w-full w-full"
                    clearable
                    placeholder="搜尋名稱"
                    @blur="filterSearch"
                    @keyup.enter.native="filterSearch"
                    @clear="searchName = null"></el-input>
            </div>
        </div>
        <div>
            <ul id="chatUsers" ref="chatUsers" class="overflow-y-auto max-h-[500px] bg-white">
                <li
                    v-for="(item, index) in chatUsers"
                    :id="item.banana_id"
                    :key="index"
                    class="cursor-pointer flex items-center px-5 py-2.5"
                    :class="item.banana_id === currentReceiveUserId ? 'bg-yellow-100' : 'bg-white'"
                    @click="changeRoom(item.banana_id, item.role)">
                    <Avatar :backgroundImg="item.avatar" />
                    <div class="ml-3 flex-1">
                        <!-- 只有待約會才會顯示時間 (顯示時間為 開始約會的時間倒數) -->
                        <div class="flex">
                            <h5 class="flex-1 font-medium">{{ item.name }}</h5>
                            <span v-if="$Debug">-{{ item.banana_id }}</span>
                            <span class="text-xs pt-1 font-light text-gray-300 OpenSansFont">{{ item.lastMsgAt | formatMonthAndDayTime }}</span>
                        </div>
                        <div class="flex mt-1 items-center">
                            <!-- 最後一筆聊天室內容 -->
                            <p class="flex-1 text-gray-300 text-xs">
                                {{ $subString(item.message, 30) }}
                            </p>
                            <!-- 未讀訊息數量 -->
                            <span v-if="item.unReadMessageCount > 0" class="bg-red-600 rounded-full w-5 h-5 text-xs text-white text-center pt-0.5">{{
                                item.unReadMessageCount > 1000 ? 999 : item.unReadMessageCount
                            }}</span>
                        </div>
                    </div>
                </li>
                <li v-if="loading" class="min-h-[50px] w-full text-center">
                    <img class="w-10 h-10 mx-auto block animate-spin" src="/img/loading.svg" alt="" />
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
// 導入大頭照組件
import Avatar from "@/components/Avatar";
// 導入 firebase 連接資料庫機制
import { firebaseConnectRef } from "@/plugins/firebase";
/**
 * firebaseGetChatRoomUserData: 取得 firebase 聊天對象資料
 * firebaseSetReadedTime: 更新聊天對象已讀訊息時間
 */
/* eslint no-unused-vars: 0 */
import { firebaseGetChatRoomUserData, firebaseSetReadedTime } from "@/service/firebaseChatSet";
export default {
    components: {
        Avatar,
    },
    props: {
        // 客服聊天室id
        serviceChatId: {
            type: String,
            default: null,
        },
        // 當前聊天對象資料
        currentChatUserData: {
            type: Object,
            default() {
                return {};
            },
        },
        // 當前聊天對象id
        currentReceiveUserId: {
            type: [String, Number],
            default: null,
        },
    },
    computed: {
        ...mapState("userStore", ["user"]),
        ...mapState("chatStore", ["unReadMessageCountByMember", "unReadMessageCountByProvider", "lastKey", "chatTab", "chatUsers"]),
        ...mapGetters("chatStore", ["realTimeChatUsers"]),
    },
    data() {
        return {
            // 聊天對象分類
            tabs: [
                { name: "會員", key: "members" },
                { name: "服務商", key: "providers" },
            ],
            // 當前tab (預設值為會員)
            currentTab: "members",
            // 搜尋聊天對象
            searchName: "",
            // 判斷目前是往下滾還是網上滾
            isScrollDown: true,
            loading: false,
        };
    },
    methods: {
        ...mapMutations("chatStore", ["setLastKey", "setChatUsersByIndex", "setAssignChatUser"]),
        ...mapActions("chatStore", ["getChatUsers", "getChatUsersPagination", "searchChatUsers"]),
        // 重置
        reset() {
            this.searchName = null;
        },
        // 搜尋條件
        async filterSearch() {
            // 判斷是否有值 有值就帶入搜尋條件
            if (this.$isEmpty(this.searchName)) {
                this.getUserList();
                return;
            }
            this.searchChatUsers({
                isGetProviders: false,
                userId: this.serviceChatId,
                name: this.searchName,
                firebaseQuery: this.currentTab,
            });
        },
        /**
         * 搜尋過濾條件 模糊比對方式
         * @param { type Array(陣列) } list 過濾名單
         * @param { type String(字串) } keyword 過濾關鍵字
         */
        fuzzyQuery(list, keyword) {
            var arr = [];
            list.forEach((item) => {
                if (item.name.indexOf(keyword) >= 0) {
                    arr.push(item);
                }
            });
            return arr;
        },
        /**
         * 更換聊天室
         * @param { type String(字串) } userId 會員id
         * @param { type Boolean(布林) } isChangeLastKey 判斷是否重新定義 firebase 分頁 lastKey (因為重新整理頁面時不可觸發 因此多傳個值判斷)
         */
        async changeRoom(userId, isChangeLastKey = true) {
            if (userId === this.currentReceiveUserId) {
                return;
            }
            // 判斷有選擇服務商以及聊天對象時 才觸發
            if (this.serviceChatId !== null && this.currentReceiveUserId !== null) {
                // 取消監聽聊天對象是否有資料更新
                this.disconnectMonitorUser(this.currentReceiveUserId);
            }
            // 監聽聊天對象是否有資料更新
            this.monitorUser(userId);

            // 設定聊天對象id
            this.$emit("update:currentReceiveUserId", userId);
            this.$nextTick(async () => {
                // 取消原本監聽 (為了防止多重聊天室監聽情況發生)
                this.disconnect();
                // 取消讀取歷史訊息事件
                this.$emit("update:isReadHistory", false);
                // 清空聊天對象資料
                this.$emit("update:currentChatUserData", {});
                // 清空輸入匡值
                this.$emit("onClearnMessage");
                // 重新取得對應聊天室資料
                this.$emit("onGetMessageList");
                // 更新當前聊天對象資料
                await this.setCurrentChatUserData(isChangeLastKey);
                // 判斷有搜尋聊天對象時 不往下執行
                if (!this.$isEmpty(this.searchName)) {
                    return;
                }
                this.$nextTick(async () => {
                    // 判斷當高度小於 500 時需動態更新視窗高度 因為預設高度最高限制 500 不然會沒有捲軸出現 無法觸發分頁機制
                    if (this.$refs.chatUsers.scrollHeight < 500) {
                        await this.scrollDownGetPagination();
                    }
                });
            });
        },
        /**
         * 當前聊天對象資料
         * @param { type Boolean(布林) } isChangeLastKey 判斷是否重新定義 firebase 分頁 lastKey (因為重新整理頁面時不可觸發 因此多傳個值判斷)
         */
        async setCurrentChatUserData(isChangeLastKey = true) {
            try {
                const userData = await firebaseGetChatRoomUserData(this.currentReceiveUserId, true, this.serviceChatId);
                // 當使用者資料沒有時 不往下執行
                if (userData === null) {
                    return;
                }
                // 判斷lastMsgAt值 不可為 undefined 或 小於 0 或者是 NaN
                if (userData.lastMsgAt !== null && isChangeLastKey) {
                    this.setLastKey({
                        isGetProviders: false,
                        lastKey: -userData.lastMsgAt,
                    });
                }
                // 用來更新切換機器人或真人客服時 找尋到對應的 index 去做更換聊天對象的顯示
                const index = this.realTimeChatUsers.findIndex((item) => {
                    return item.banana_id === userData.banana_id;
                });
                // 判斷有找到值時 才執行 去做更換聊天對象的顯示
                if (index !== -1) {
                    this.setChatUsersByIndex({
                        isGetProviders: false,
                        index,
                        data: userData,
                    });
                }
                // 更新當前聊天對象資料
                this.$emit("update:currentChatUserData", userData);
            } catch (err) {
                return {};
            }
        },
        // 取消 firebase 監聽
        disconnect() {
            // 判斷是否有 user 資料 因為登出時會清出空
            if (this.serviceChatId !== undefined) {
                // 取消聊天對象未讀訊息數量監聽
                firebaseConnectRef("chat_rooms").child(this.currentReceiveUserId).child("users").child(this.serviceChatId).off();
                // 取消聊天室監聽
                firebaseConnectRef("chats").child("users").child(this.currentReceiveUserId).child("messages").off();
            }
        },
        // 取得分頁資料
        getMoreData() {
            // 抓取元素
            const dom = this.$refs.chatUsers;
            // 判斷當 滾動距離 加上 當前 div 高度 >= 滾動條高度時 觸發滾動更新分頁
            const getDataStart = dom.scrollTop + dom.clientHeight >= dom.scrollHeight;
            // 觸發滾動更新分頁
            if (getDataStart && this.$isEmpty(this.searchName)) {
                this.scrollDownGetPagination();
                return;
            }
            // //  當滾動高度 小於或等於 新人榜高度時做觸發
            if (dom.scrollTop <= 0) {
                // this.scrollUpGetPrevPagination();
            }
        },
        /**
         * 取得聊天對象列表
         * @param { type String(字串) } query 區分找尋會員或服務商聊天對象參數 (ex: "members" 會員, "providers" 服務商)
         * @example 服務商 [1,2] 會員 [0,0]
         */
        async getUserList(query = "members") {
            await this.getChatUsers({
                isGetProviders: false,
                userId: this.serviceChatId,
                firebaseQuery: query,
                lastKey: this.lastKey,
            });
            this.$nextTick(async () => {
                // 當捲軸高度小於500  且聊天對象大於0時 觸發
                if (this.$refs.chatUsers.scrollHeight < 500 && this.realTimeChatUsers.length > 0) {
                    await this.scrollDownGetPagination();
                }
            });
        },
        // 顯示載入動畫時間
        loadingTime() {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve(true);
                }, 500);
            });
        },
        // 取消監聽聊天對象是否有資料更新
        disconnectMonitorUser(receiveUserId) {
            firebaseConnectRef("chat_rooms").child(this.serviceChatId).child("users").child(receiveUserId).off();
        },
        /**
         * 監聽聊天對象是否有資料更新
         */
        monitorUser(receiveUserId) {
            firebaseConnectRef("chat_rooms")
                .child(this.serviceChatId)
                .child("users")
                .child(receiveUserId)
                .on("child_changed", (snapshot) => {
                    // 判斷非登入使用者
                    if (snapshot.key === "readedAt") {
                        // 取得聊天對象更改資料者使用者
                        const receiveUserIndex = this.chatUsers.findIndex((user) => user.banana_id === receiveUserId);
                        // 判斷是否有取得
                        if (receiveUserIndex !== -1) {
                            this.setAssignChatUser({
                                isGetProviders: true,
                                index: receiveUserIndex,
                                readedAt: snapshot.val(),
                            });
                            // // 將使用者資料更新為最新結果
                            this.setCurrentChatUserData();
                        }
                    }
                });
        },
        /**
         * 分頁機制
         */
        async scrollDownGetPagination() {
            this.loading = true;
            // 判斷目前是往下滾狀態
            this.isScrollDown = true;
            if (this.lastKey === "end") {
                console.log("is end");
                this.loading = false;
                return;
            }
            await this.loadingTime();
            await this.getChatUsersPagination({
                isGetProviders: false,
                userId: this.serviceChatId,
                currentReceiveUserId: this.currentReceiveUserId,
                firebaseQuery: this.currentTab,
                lastKey: this.lastKey,
                allUsers: this.realTimeChatUsers,
            });
            this.loading = false;
            this.$nextTick(async () => {
                // 判斷當高度小於 500 時觸發
                if (this.$refs.chatUsers.scrollHeight < 500) {
                    // 當目前卷軸高度小於聊天對象的視窗高度(500)時 觸發分頁機制 遞迴自己 (因為分頁中取得比數會因為過濾條件完後 變成筆數過少問題 因此遞迴自己)
                    await this.scrollDownGetPagination();
                } else {
                    // 如果超過 500 高度時 將高度設為 auto
                    // this.$refs.chatUsers.style.height = "auto";
                }
                const dom = this.$refs.chatUsers;
                dom.scrollTo({ top: dom.scrollTop });
            });
        },
        // 觸發視窗滾動事件
        windowSrcoll() {
            this.$nextTick(() => {
                this.$refs.chatUsers.addEventListener("scroll", this.getMoreData);
            });
        },
        /**
         * 設定分頁資料
         * @param { type Array(陣列) } data 分頁資料
         */
        setDatas() {
            this.windowSrcoll();
        },
    },
    watch: {
        // 當聊天對象 id 更動時 重新取得聊天對象資料
        async serviceChatId() {
            try {
                await this.getUserList();
            } catch (err) {
                return err;
            }
        },
        // 判斷當清空輸入搜尋名稱時 觸發搜尋條件
        searchName(val) {
            if (this.$isEmpty(val)) {
                this.filterSearch();
            }
        },
    },
    async created() {
        // 先清空預設定位分頁的 key
        this.setLastKey({ isGetProviders: false, lastKey: null });
        if (this.serviceChatId !== null) {
            try {
                await this.getUserList();
            } catch (err) {
                console.log(err);
            }
        }
        this.setDatas();
    },
};
</script>
